import { useSpring, animated } from '@react-spring/web'
import { HeadingLg } from '/features/buildingBlocks/Heading'
import { TextLg } from '/features/buildingBlocks/Text'
import { ImageCover } from '/features/buildingBlocks/Image'
import { Video } from '/features/buildingBlocks/Video'

import styles from './HeroTertiary.css'

export function HeroTertiary({
  title,
  text,
  image,
  videoUrls = undefined,
  videoUrl = undefined,
  videoTitle = undefined,
  buttonText = undefined,
  layoutClassName = undefined,
}) {
  const springConfig = {
    to: { opacity: 1, transform: 'translateY(0)' },
    from: { opacity: 0, transform: 'translateY(40px)' },
    config: { tension: 100, friction: 35 },
  }

  const headingSpring = useSpring({ ...springConfig, delay: 200 })
  const textSpring = useSpring({ ...springConfig, delay: 400 })

  return (
    <header className={cx(styles.component, layoutClassName)} data-x='hero'>
      <div className={styles.content}>
        <animated.div style={headingSpring}>
          <HeadingLg h='1'>{title}</HeadingLg>
        </animated.div>
        <animated.div style={textSpring}>
          <TextLg>{text}</TextLg>
        </animated.div>
      </div>
      <div className={styles.mediaContainer}>
        {videoUrl && (
          <Video {...{ videoUrls, videoUrl, videoTitle, buttonText }} />
        )}
        {image && !videoUrls && (
          <ImageCover
            aspectRatio={3 / 2}
            {...{ image }}
            sizes='50vw'
            imgProps={{ fetchpriority: 'high' }}
          />
        )}
      </div>
      <div role='presentation' className={styles.background} />
    </header>
  )
}
