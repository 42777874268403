import styles from './Text.css'

export function TextMd({ children, layoutClassName = undefined }) {
  return (
    <p className={cx(styles.componentMd, layoutClassName)}>{children}</p>
  )
}

export function TextLg({ children, layoutClassName = undefined }) {
  return (
    <p className={cx(styles.componentLg, layoutClassName)}>{children}</p>
  )
}

export function TextXl({ children, layoutClassName = undefined }) {
  return (
    <p className={cx(styles.componentXl, layoutClassName)}>{children}</p>
  )
}
